<template>
	<div class="page-layout">
		<div class="page__header sticky-section py-6">
			<div class="subtitle-1 font-weight-bold px-10 mb-2 mx-auto base-content__max-width">
				<slot name="page__direction"></slot>
			</div>
			<div class="d-flex justify-space-between px-10 mx-auto base-content__max-width">
				<div class="text-h4 font-weight-bold mr-3 forth--text">
					<slot name="page__title"></slot>
				</div>
				<div class="d-flex">
					<slot name="page__actions"></slot>
				</div>
			</div>
		</div>
		<div class="full-width">
			<v-progress-linear indeterminate v-if="loading"/>
			<div class="mx-auto base-content__max-width page-content">
				<slot></slot>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		loading: Boolean
	}
}
</script>
<style lang="scss" scoped>
.page-layout {
	min-height: 100vh;
}
.page__header {
	background: #f1f2f5;
	min-height: 124px;
}
</style>
